@media only screen and (max-width:789px) {

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color:#F0F0F0 ;
        transition: 0.5s;
        transform: translateY(-100vh);
    }
  
    header .responsive_navbar{
        transform: none;
    }
  
    nav .navbar-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
  }

