@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root{
  --primary-color: #DC143C;
  --secondary-color: #8C0909;
  --bg-color1:#fff;
  --bg-color2:#f0f0f0;
  --text-color:#000000;
} 

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.submitBtn {
  background-color: var(--primary-color);
}

.button {
  background-color: var(--secondary-color);
}

.nobtn {
  background-color: #FFFFFF;
  border-style:double;
  border-color: #8C0909;
}

/* Hover state styles */
.button:hover {
  background-color: var(--primary-color);
}

.active-text-red {
  color: red;
}

.connect-wallet-button button {
  background-color: transparent;; 
  color: #DC143C;
  border: 1px solid #8C0909;
  height: 50px;
  border-radius: 0.9rem;
  margin-bottom: 2px;
}

.connect-wallet-button :hover{
  background-color:#f0f0f0; 
}

.web3-button button{
  width: 100%;
  height:33px;
  color: #FFFFFF;
  background-color:var(--secondary-color);
  
}

.web3-button :hover{
  background-color: var(--primary-color);
}

